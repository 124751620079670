import React from "react"
import { graphql, Link } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { FiArrowLeft } from "react-icons/fi"

import { Container, Wrapper, Row, Box } from "../components/util"
import { IframeContainer } from "../components/site/"
import Layout from "../components/layout"

import Seo from "../components/seo"

export const query = graphql`
  query ($slug: String!) {
    contentfulWork(url: { eq: $slug }) {
      client
      excerpt {
        internal {
          content
        }
      }
      tags
      website
      colour
      mainImage {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    }
  }
`

const Work = props => {
  //   const website_url = process.env.GATSBY_SITE_URL;
  const website_url = "https://www.jamesbarber.co.nz"

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        if (
          node.data.target.link.startsWith(website_url) ||
          node.data.target.link.startsWith("/") ||
          node.data.target.link.startsWith("#")
        ) {
          return (
            <Link to={node.data.target.link}>
              <GatsbyImage
                className="-rounded -addShadow"
                image={node.data.target.image.gatsbyImageData}
                alt={node.data.target.imageAltText}
                title={node.data.target.imageAltText}
              />
            </Link>
          )
        } else {
          return (
            <a
              href={node.data.target.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                className="-rounded -addShadow"
                image={node.data.target.image.gatsbyImageData}
                alt={node.data.target.imageAltText}
                title={node.data.target.imageAltText}
              />
            </a>
          )
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <GatsbyImage
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.title}
            title={node.data.target.title}
          />
        )
      },
      [INLINES.HYPERLINK]: node => {
        if (node.data.uri.startsWith("#ID=")) {
          return (
            <span
              className="post-anchor"
              css={{ opacity: "0" }}
              id={node.data.uri.replace("#ID=", "")}
            >
              &nbsp;
            </span>
          )
        } else if (node.data.uri.includes("player.vimeo.com/video")) {
          return (
            <IframeContainer title="PayHero Vimeo Video" src={node.data.uri} />
          )
        } else if (node.data.uri.includes("youtube.com/embed")) {
          return (
            <IframeContainer
              title="PayHero YouTube Video"
              src={node.data.uri}
            />
          )
        } else if (
          node.data.uri.startsWith(website_url) ||
          node.data.uri.startsWith("/") ||
          node.data.uri.startsWith("#")
        ) {
          return (
            <Link to={node.data.uri}>
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>
              })}
            </Link>
          )
        } else {
          return (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>
              })}
            </a>
          )
        }
      },
    },
  }

  const WorkContent = props.data.contentfulWork

  return (
    <Layout>
      <Seo title={WorkContent.client + " | James Barber"} description="desc" />
      <Container>
        <Wrapper className="-center -whiteOut" maxWidth={900} noPaddingBottom>
          <Box gap={30}>
            <Link to="/">
              <FiArrowLeft css={{ top: "2px" }} /> Back
            </Link>
            <Box gap={10}>
              <h1 className="-fontLarge">{WorkContent.client}</h1>
              <h2>{WorkContent.excerpt.internal.content}</h2>
            </Box>
          </Box>
          <Box
            css={{
              padding: "40px",
              borderRadius: "10px",
              backgroundColor: WorkContent.colour,
            }}
          >
            <GatsbyImage
              image={WorkContent.mainImage.gatsbyImageData}
              css={{
                boxShadow: "0 0 100px rgba(0, 0, 0, 0.3)",
                borderRadius: "10px",
              }}
            />
          </Box>
          <Box gap={50}>
            <Box gap={10}>
              <h4>Role</h4>
              <h2>Design Lead</h2>
            </Box>
            <Box gap={10}>
              <h4>Skills</h4>
              {WorkContent.tags && (
                <Row justify="flex-start" noBreak isWrap>
                  {WorkContent.tags.map(i => {
                    return (
                      <div
                        key={i}
                        className="badge -white"
                        css={{
                          marginRight: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {i}
                      </div>
                    )
                  })}
                </Row>
              )}
            </Box>
            {WorkContent.website && (
              <Box gap={10}>
                <h4>Link</h4>
                <h2>
                  <a href={WorkContent.website} target="_blank">
                    {WorkContent.website}
                  </a>
                </h2>
              </Box>
            )}
          </Box>
          <hr css={{ backgroundColor: "#fff", opacity: "0.2" }} />
        </Wrapper>
      </Container>
      <Container className="-whiteOut">
        <Wrapper maxWidth={900}>
          <div className="font-bump">
            {renderRichText(WorkContent.description, options)}
          </div>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default Work
