import React from "react";
import styled from "styled-components";

const IframeEmbedContainer = styled.span`
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;
  border-radius: ${(props) => props.theme.styles.borderRadius};

  &.-noBottomRadius {
    border-radius: ${(props) => props.theme.styles.borderRadius}
      ${(props) => props.theme.styles.borderRadius} 0 0;

    > iframe {
      border-radius: ${(props) => props.theme.styles.borderRadius}
        ${(props) => props.theme.styles.borderRadius} 0 0;
    }
  }

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${(props) => props.theme.styles.borderRadius};
  }
`;

export const IframeContainer = ({ src, title, noBottomRadius }) => {
  return (
    <IframeEmbedContainer className={noBottomRadius ? "-noBottomRadius" : ""}>
      <iframe
        src={src}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </IframeEmbedContainer>
  );
};
